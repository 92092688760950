.shoppingCartButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .text {
    margin: 0 .5rem;
  }

  .numberInput {
    margin-right: 1rem;
  }
}