@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap);
body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.product{width:100%}.product .productHeader{width:100%;overflow:hidden;position:relative;padding-bottom:100%}.product .productHeader img{position:absolute;left:0;top:0;width:100%;height:100%;object-fit:cover;object-position:center center}.product .productContent,.product .productActions{padding-bottom:.5rem;padding-top:.5rem}.product .productActions{display:flex;flex-wrap:wrap;justify-content:space-between}
.numberInput{display:flex;align-items:center;justify-content:space-between;border:1px solid #b6b6b6;border-radius:8px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.numberInput .value{margin:0 1rem;width:10px}.numberInput .toggleButton{background:#b6b6b6;border-radius:7px}.numberInput input{border:none;text-align:center;display:flex;align-items:center;justify-content:center}
.shoppingCartButton{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center}.shoppingCartButton .text{margin:0 .5rem}.shoppingCartButton .numberInput{margin-right:1rem}
.popup-modal{display:flex;align-items:center;justify-content:center;background:none;-webkit-backdrop-filter:blur(5);backdrop-filter:blur(5)}.popup-modal .popup{background:#fff;margin:16px;overflow:scroll;position:relative}.popup-modal .popup .popupClose{width:100%;position:absolute;text-align:right;z-index:500;margin-top:1rem}.popup-modal .popup .popupClose button{background:rgba(255,255,255,.5)}.popup-modal .popup .popupHeader{display:flex;flex-direction:row-reverse;margin-top:.5rem}.popup-modal .popup .popupMedia{position:relative;padding-bottom:50%;width:100%}.popup-modal .popup .popupMedia img{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover}.popup-modal .popup .popupContent{height:100%;padding-bottom:16px;display:flex;flex-direction:column;justify-content:space-between}.popup-modal .popup .popupContent .popupText .headline,.popup-modal .popup .popupContent .popupText .bodyText{margin:2rem 0}.popup-modal .popup .popupContent .popupActions{margin:1rem 0;display:flex;justify-content:space-between;align-items:center}@media screen and (min-width: 900px){.popup-modal .popup .popupContent{padding-top:2rem;padding-bottom:2rem}}
.shoppingCartStep h3{margin:1rem 0}.shoppingCartStep .shoppingList{margin:1rem 0;padding:0;width:100%;table-layout:fixed}.shoppingCartStep .shoppingList tr{height:50px}.shoppingCartStep .shoppingList tr td{height:80px}.shoppingCartStep .shoppingList .product{width:55%}.shoppingCartStep .shoppingList .count{min-width:20px;width:30%}.shoppingCartStep .shoppingList .price{min-width:20px;width:10%}.shoppingCartStep .shoppingList .buttons{min-width:20px;width:5%}.shoppingCartStep .shoppingList td{overflow-x:hidden}.shoppingCartStep .shoppingList .shoppingListItem{display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid #d3d3d3}.shoppingCartStep .shoppingList .shoppingListItem .name{width:30%}.shoppingCartStep .shoppingList .shoppingListItem .count{width:10%}.shoppingCartStep .shoppingList .shoppingListItem .sum{width:10%}.shoppingCartStep .shoppingList .addProduct .MuiFormControl-root{margin-right:1rem}.shoppingCartStep .shoppingList .addProduct .MuiFormControl-root:last-child{margin-right:0}.shoppingCartStep .shoppingList .addProduct .buttonHolder,.shoppingCartStep .shoppingList .addProduct .sum{display:flex;align-items:center}
.order .orderForm .orderStepper{padding:1rem}.order .orderForm .orderStepper .stepBar,.order .orderForm .orderStepper .stepWrapper,.order .orderForm .orderStepper .stepContent,.order .orderForm .orderStepper .stepActions{margin:1rem 0}.order .orderForm .orderStepper .stepActions{text-align:right}.order .orderForm .orderStepper .stepActions button{margin-left:1rem}
