.order {
  .orderForm {
    .orderStepper {
      padding: 1rem;

      .stepBar, .stepWrapper, .stepContent, .stepActions {
        margin: 1rem 0;
      }

      .stepActions {
        text-align: right;

        button {
          margin-left: 1rem;
        }
      }
    }
  }
}