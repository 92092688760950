.numberInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b6b6b6;
  border-radius: 8px;
  width: fit-content;


  .value {
    margin: 0 1rem;
    width: 10px;
  }

  .toggleButton {
    background: #b6b6b6;
    border-radius: 7px;
  }

  input {
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}