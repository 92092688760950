
.popup-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  backdrop-filter: blur(5);

  .popup {
    background: #fff;
    margin: 16px;
    overflow: scroll;
    position: relative;

    .popupClose {
      width: 100%;
      position: absolute;
      text-align: right;
      z-index: 500;
      margin-top: 1rem;

      button {
        background: rgba(255, 255, 255, .5);
      }
    }

    .popupHeader {
      display: flex;
      flex-direction: row-reverse;
      margin-top: .5rem;
    }

    .popupMedia {
      position: relative;
      padding-bottom: 50%;
      width: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .popupContent {
      height: 100%;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .popupText {
        .headline, .bodyText {
          margin: 2rem 0;
        }
      }

      .popupActions {
        margin: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .popup-modal .popup .popupContent {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

}