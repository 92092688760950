.product {
  width: 100%;

  .productHeader {
    width: 100%;
    // max-height: 350px;
    overflow: hidden;
    position: relative;
    padding-bottom: 100%;


    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .productContent, .productActions {
    padding-bottom: .5rem;
    padding-top: .5rem;
  }

  .productActions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}