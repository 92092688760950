.shoppingCartStep {
  h3 {
    margin: 1rem 0;
  }

  .shoppingList {
    margin: 1rem 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    tr {
      td {
        height: 80px;
      }

      height: 50px;
    }

    .product {
      width: 55%;
    }

    .count {
      min-width: 20px;
      width: 30%;
    }

    .price {
      min-width: 20px;
      width: 10%;
    }

    .buttons {
      min-width: 20px;
      width: 5%;
    }

    td {
      overflow-x: hidden;
    }

    .shoppingListItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid lightgrey;

      .name {
        width: 30%;
      }

      .count {
        width: 10%;
      }

      .sum {
        width: 10%;
      }
    }

    .addProduct {
      .MuiFormControl-root {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .buttonHolder, .sum {
        display: flex;
        align-items: center;
      }
    }
  }

}